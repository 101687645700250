<template>

  <vue-plyr :ref="'plyr'+idb" :options="options">
    <video
        @timeupdate="setCurrenttimeToPlaylistItem(idb)"
        crossorigin
        playsinline
        :data-poster=video_poster
        :src="video_src.length === 0 ? '': cdnURL+video_src"
    >
    </video>
  </vue-plyr>
</template>

<script>
import Vue from "vue";
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

export default Vue.extend({
  name: "VideoPlayer",
  components:{
    VuePlyr
  },
  props:{
    video_src: {},
    video_poster: {},
    idb:Number
  },
  data: () => ({
      options: {
        quality: { default: '1080p' } ,
        controls: ['play-large', 'play', 'progress', 'current-time','fullscreen'],
        fullscreen: {iosNative: true},
        iconUrl: require('@/assets/plyr.svg')
      },
    cdnURL: "https://cdn.pfep.de/media/"
    }),
  mounted () {
    this.playcheck();
  },
  methods:{
    setCurrenttimeToPlaylistItem(idb){
      if(idb){
        const value = this.$refs["plyr"+idb].player.currentTime*100/this.$refs["plyr"+idb].player.duration;
        this.$store.commit("ADD_PLAYLISTITEMTIMEVALUE", [idb,value]);
      }
    },
    playcheck(){
      this.$refs["plyr"+this.idb].player.on('play', function (v){
        const collection = Array.from(document.getElementsByTagName("video"));
        collection.forEach(function (videop){
          if(videop.plyr.id !== v.detail.plyr.id){
            videop.pause();
          }
        });
      })
    },

  }

})
</script>

<style scoped>

</style>